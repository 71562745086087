<template>
  <div
    v-if="showVerifiedCompare || closeVerifiedCompare || showShare || showReport"
    class="d-flex justify-space-between align-center">
    <div v-if="showVerifiedCompare || closeVerifiedCompare">
      <!-- compare with verified -->
      <v-btn
        v-if="showVerifiedCompare"
        class="sourcery__has-icon fs-14"
        outlined
        @click="$emit('show-verified')">
        <span class="sourcery__icon-wrapper green">
          <img src="@/assets/icons/verified-icon.svg">
        </span>
        Compare with Verified
      </v-btn>
      <!-- close comparison -->
      <v-btn
        v-if="closeVerifiedCompare"
        class="sourcery__has-icon fs-14"
        outlined
        @click="$emit('close-verified')">
        <span class="sourcery__icon-wrapper green">
          <img src="@/assets/icons/verified-icon.svg">
        </span>
        Close Verified Compare
      </v-btn>
    </div>

    <div
      v-if="showShare || showReport"
      class="flex-grow-1 d-flex justify-end align-center gap-3">
      <!-- share product -->
      <v-avatar
        v-if="showShare"
        class="pa-2 sourcery__avatar"
        size="40"
        @click="$emit('share')">
        <img
          src="@/assets/icons/arrow-share-icon.svg"
          class="avatar-img"
          height="16"
          width="16">
      </v-avatar>

      <!-- report product -->
      <v-avatar
        v-if="showReport"
        class="pa-2 sourcery__avatar"
        size="40"
        @click="$emit('report')">
        <img
          src="@/assets/icons/comment-question-icon.svg"
          class="avatar-img"
          height="16"
          width="16">
      </v-avatar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionsWrapper',
  props: {
    showVerifiedCompare: {
      type: Boolean,
      default: false,
    },
    closeVerifiedCompare: {
      type: Boolean,
      default: false,
    },
    showShare: {
      type: Boolean,
      default: false,
    },
    showReport: {
      type: Boolean,
      default: false,
    },
  },
};
</script>